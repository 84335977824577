import React, { Component } from 'react';
import TeslaIcon from '../../../icons/TeslaIcon';

class WaterMark extends Component {
    constructor(props) {
        super(props);
        this.title = props.title;
        this.state = {
            watermaskArr: []
        };
        this.watermaskRef = React.createRef();
    }

    componentDidMount() {
        let widthInterval = 150;
        let heightInterval = 60;
        if (this.watermaskRef) {
            let {clientWidth, clientHeight} = this.watermaskRef.current;
            this.setState({
                watermaskArr: new Array(Math.ceil(clientHeight / heightInterval) + 2).fill(new Array(Math.ceil(clientWidth / widthInterval) + 1 ).fill(undefined)),
            }, () => {
                // console.error(this.state.watermaskArr)
            });
        }
    }

    render() {
        const { watermaskArr } = this.state;
        return (
            <div className="tw-w-full tw-h-full gotham-font tw-absolute tw-left-[0px] tw-top-[-50px] tw-z-[100]" style={{  transform: 'rotate(-20deg)' }} ref={this.watermaskRef}>
                {watermaskArr.map((item, index) => {
                    return (<div className='tw-w-full tw-h-[50px] tw-flex' key={index}>
                        {item.map((icon, i) => (<TeslaIcon key={i} size={80} color={'rgba(0,0,0,0.6)'} className="tw-mr-[30px]"/>))}
                    </div>)
                })}
            </div>
        );
    }
}

export default WaterMark;
