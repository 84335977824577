import React, { useEffect, useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import { BASE_URL } from "../../../constants/api/ApiEndpointConstants";
import PagePathConstants from "../../../constants/router/PagePathConstants";

export default function CheckoutForm(props) {
    const { cSiteUrl, paymentIntentId, orderId, orderNo, subscriptionId, customerInfo } = props;

    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [retryCount, setRetryCount] = useState(0);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            handleVerification(paymentIntent);

            // switch (paymentIntent.status) {
            //     case "succeeded":
            //         setMessage("Payment succeeded!");
            //         break;
            //     case "processing":
            //         setMessage("Your payment is processing.");
            //         break;
            //     case "requires_payment_method":
            //         setMessage("Your payment was not successful, please try again.");
            //         break;
            //     default:
            //         setMessage("Something went wrong.");
            //         break;
            // }
        });
    }, [stripe]);



    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);
        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${BASE_URL}${PagePathConstants.SUCCESS_CALLBACK}?origin=payment&state=1&email=${customerInfo.email}&phone=${customerInfo.phone}&orderId=${orderId}&orderNo=${orderNo}&cSiteUrl=${cSiteUrl}&paymentIntentId=${paymentIntentId}&subscriptionId=${subscriptionId}`,
                payment_method_data: {
                    billing_details: customerInfo
                }
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.

        // if (error.type === "card_error" || error.type === "validation_error") {
        //     setMessage(error.message);
        // } else {
        //     setMessage("An unexpected error occurred.");
        // }

        if (error) {
            handlePaymentError(error);
        } else if (paymentIntent.status === "requires_action" || paymentIntent.status === "requires_source_action") {
            handleVerification(paymentIntent);
        } else if (paymentIntent.status === "succeeded") {
            setMessage("Payment succeeded!");
        } else {
            setMessage("Something went wrong.");
        }

        setIsLoading(false);
    };

    const handlePaymentError = (error) => {
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else if (error.type === "invalid_request_error") {
            setMessage("Invalid request. Please check the payment details.");
        } else if (error.type === "api_error") {
            setMessage("Payment server is currently unavailable. Please try again later.");
        } else {
            setMessage("An unexpected error occurred.");
        }

        // Retry logic
        if (retryCount < 3) {
            setRetryCount(retryCount + 1);
            handleSubmit(new Event("submit")); // Retry submission
        } else {
            setMessage("Payment failed after multiple attempts. Please try again later.");
        }
    };

    const handleVerification = async (paymentIntent) => {
        const nextAction = paymentIntent.next_action;

        if (nextAction) {
            switch (nextAction.type) {
                case "use_stripe_sdk": {
                    const { error } = await stripe.handleCardAction(paymentIntent.client_secret);
                    if (error) {
                        setMessage("Verification failed. Please try again.");
                    } else {
                        setMessage("Payment verification succeeded!");
                    }
                } break;
                case "redirect_to_url":
                    window.location.href = nextAction.redirect_to_url.url;
                    break;
                case "pay_by_link":
                    window.location.href = nextAction.pay_by_link.payment_link;
                    break;
                case "authorize_with_bank":
                    window.location.href = nextAction.authorize_with_bank.bank_authorization_url;
                    break;
                default:
                    setMessage("Unsupported verification action.");
                    break;
            }
        } else {
            setMessage("No additional action required.");
        }
    };

    const paymentElementOptions = {
        layout: "tabs",
        fields: {
            billingDetails: {
                name: 'never',
                email: 'never',
                address: {
                    // city: 'never',
                    state: 'never',
                    country: 'never',
                    postalCode: 'never'
                }
            }
        }
    }

    return (
        <form id="payment-form" className={`hs-stripe-custom-form form ${isLoading ? 'form-disabled' : ''}`} onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" options={paymentElementOptions} />

            <button disabled={isLoading || !stripe || !elements} id="submit">
                <span id="button-text">
                    {isLoading ? <div className="spinner" id="spinner"></div> : 'ペイナウ'}
                </span>
            </button>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}