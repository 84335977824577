import { Map } from 'immutable';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ActionConstants from '../constants/actions/ActionConstants';

const initialState = Map({
  [StoreStateConstants.COUNTDOWN_TIME_START]: 0,
  [StoreStateConstants.COUNTING_DOWN_STATUS]: false,
});

export const CountdownReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionConstants.UPDATE_COUNTING_DOWN_STATUS: {
      return state.set(StoreStateConstants.COUNTING_DOWN_STATUS, action.payload);
    }
    case ActionConstants.UPDATE_COUNTDOWN_START_TIME: {
      // console.log('这里~~~~~')
      // console.log(action.payload)
      return state.set(StoreStateConstants.COUNTDOWN_TIME_START, action.payload);
    }
    default:
      return state;
  }
};
