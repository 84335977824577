import { Map } from 'immutable';
import LocalStorageConstants from '../constants/LocalStorageConstants';
class LocalStorageUtil {
  static setItem(key, value) {
    try {
      localStorage.setItem(key, value);
    }
    catch (error) {
      // No need to alert bugsnag. Some browsers in private mode do not have access to localstorage and so exceptions are expected
    }
  }

  static getItem(key) {
    try {
      return localStorage.getItem(key);
    }
    catch (error) {
      // No need to alert bugsnag. Some browsers in private mode do not have access to localstorage and so exceptions are expected
      return null;
    }
  }

  static removeItem(key) {
    try {
      return localStorage.removeItem(key);
    }
    catch (error) {
      // No need to alert bugsnag. Some browsers in private mode do not have access to localstorage and so exceptions are expected
    }
  }

  static clear() {
    try {
      localStorage.clear();
    }
    catch (error) {
      // No need to alert bugsnag. Some browsers in private mode do not have access to localstorage and so exceptions are expected
    }
  }

  static loadState() {
    try {
      if (!this.getItem(LocalStorageConstants.CLEARED) || this.getItem(LocalStorageConstants.EDMODO_WEB_APP_STATE)) {
        this.clear();
        this.setItem(LocalStorageConstants.CLEARED, true);
      }

      let newState = Map();
      newState = this.loadConfigMode(newState);

      return newState;
    } catch (err) {
      console.log('Local Storage Immutable Error:', err);
      return undefined;
    }
  };

  static loadConfigMode(newState) {
    const mode = this.getItem(LocalStorageConstants.CONFIG_MODE);
    if (mode) {
      setAPIBaseUrl(ConfigModeUtil.setConfigMode(mode).baseUrl);
      newState = newState.setIn([ReducerNameConstants.CONFIG_MODE_REDUCER, StateConstants.CURRENT_MODE], mode);
    }
    return newState;
  }


  static syncToLocalStorage(key, newState) {
    this.setItem(key, newState && JSON.stringify(newState.toJS()[key]));
    return newState;
  }

  static saveOrderDetail(param) {
    let orderDetail = this.loadOrderDetail();
    orderDetail = Object.assign({}, orderDetail, param);
    this.setItem(LocalStorageConstants.ORDER_DETAIL, JSON.stringify(orderDetail));
  }

  static loadOrderDetail() {
    let orderDetail = this.getItem(LocalStorageConstants.ORDER_DETAIL);
    orderDetail = orderDetail ? JSON.parse(orderDetail) : {};
    return orderDetail;
  }

  static removeOrderDetail() {
    this.removeItem(LocalStorageConstants.ORDER_DETAIL);
  }

  static saveSubmitOrder(param) {
    let orderDetail = this.loadSubmitOrder();
    orderDetail = Object.assign({}, orderDetail, param);
    this.setItem(LocalStorageConstants.SUBMIT_ORDER, JSON.stringify(orderDetail));
  }

  static loadSubmitOrder() {
    let orderDetail = this.getItem(LocalStorageConstants.SUBMIT_ORDER);
    orderDetail = orderDetail ? JSON.parse(orderDetail) : {};
    return orderDetail;
  }

  static saveStripePaymentIntent(intentId) {
    this.setItem(LocalStorageConstants.ST_PAYMENT_INTENT, intentId?.toString());
  }

  static loadStripePaymentIntent() {
    return this.getItem(LocalStorageConstants.ST_PAYMENT_INTENT);
  }

  static clearStripePaymentIntent() {
    this.removeItem(LocalStorageConstants.ST_PAYMENT_INTENT);
  }

  static saveLastPaymentInfo(lastPayment) {
    this.setItem(LocalStorageConstants.LAST_PAYMENT_INFO, JSON.stringify(lastPayment));
  }

  static loadLastPaymentInfo() {
    const cachePaymentInfo = this.getItem(LocalStorageConstants.LAST_PAYMENT_INFO);
    return cachePaymentInfo && JSON.parse(cachePaymentInfo);
  }

  static saveFbAdParams(ad) {
    this.setItem(LocalStorageConstants.FB_AD, ad?.toString());
  }

  static loadFbAdParams() {
    return this.getItem(LocalStorageConstants.FB_AD);
  }

  static saveOrderId(id) {
    this.setItem(LocalStorageConstants.DRIVER_ORDER_ID, id?.toString());
  }

  static loadOrderId() {
    return this.getItem(LocalStorageConstants.DRIVER_ORDER_ID);
  }

  static clearOrderId() {
    this.removeItem(LocalStorageConstants.DRIVER_ORDER_ID);
  }



  static saveOrderNumber(id) {
    this.setItem(LocalStorageConstants.DRIVER_ORDER_NU, id?.toString());
  }

  static loadOrderNumber() {
    return this.getItem(LocalStorageConstants.DRIVER_ORDER_NU);
  }

  static clearOrderNumber() {
    this.removeItem(LocalStorageConstants.DRIVER_ORDER_NU);
  }


  
  static clearPurchaseEventFlag() {
    this.removeItem(LocalStorageConstants.PURCHASE_EVENT);
  }

  static savePurchaseEvent() {
    this.setItem(LocalStorageConstants.PURCHASE_EVENT, 'true');
  }

  static loadPurchaseEvent() {
    return this.getItem(LocalStorageConstants.PURCHASE_EVENT);
  }

}

export default LocalStorageUtil;
