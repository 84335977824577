const MobileDetect = require('mobile-detect');

class MobileDetectUtil {
  static mobileDetect(){
  	return new MobileDetect(navigator.userAgent);
  }

  static isMobile() {
    return !!MobileDetectUtil.mobileDetect().mobile();
  }

  static isTablet() {
    return !!MobileDetectUtil.mobileDetect().tablet();
  }

  static getPlatform() {
    return MobileDetectUtil.isMobile() ? 'mobile-web' : 'web';
  }

  static judgeClient() {
    let client = '';
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
      client = 'iOS';
    } else if (/(Android)/i.test(navigator.userAgent)) {  //判断Android
      client = 'Android';
    } else {
      client = 'PC';
    }
    return client;
  }
}

export default MobileDetectUtil;
