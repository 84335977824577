import React, { Component } from 'react';
import queryString from "query-string";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TeslaIcon from '../../icons/TeslaIcon';
import { Input } from 'reactstrap';
import RDSRadioButton from '../../components/common/RDSRadioButton';
import RDSButton from '../../components/common/RDSButton';
import RdsBackIcon from '../../icons/RdsBackIcon';
import Loading from '../../components/common/LoadingIndicator';
import WaterMark from '../../components/common/watermark/index';
import StoreStateConstants from '../../constants/store/StoreStateConstants';
import * as imgActionCreator from '../../actions/imgActionCreator';
import * as orderActionCreator from '../../actions/orderActionCreator';
import * as translationActionCreator from '../../actions/translationActionCreator';
import * as globalAlertActionCreator from '../../actions/globalAlertActionCreator';
import * as TranslationSelectors from '../../selectors/TranslationSelectors';

import DocumentBackImg from '../../images/document_back.png';
import LocalStorageUtil from '../../utils/LocalStorageUtil';
import IDCard from '../../images/card.gif';
import PagePathConstants from '../../constants/router/PagePathConstants';
import { BASE_URL } from '../../constants/api/ApiEndpointConstants';
import tracker from '../../tracker';
import { sendCustomEvent } from '../../apis/ConfigAPI';

class DocumentInfoPage extends Component {
    constructor(props, context) {
        super(props, context);
        let { translations } = props;
        translations = translations && translations['submission_page'];
        this.state = {
            identity_types: this.renderIdentityTypes(translations),
            selectedIdentityType: StoreStateConstants.IDENTITY_TYPE_IDENTITY,
            showLoading: false,
            documentFront: '',
            documentBack: '',
            showOnboardingGif: true,
        };
        this.handleSelectIdType = this.handleSelectIdType.bind(this);
        this.handleSelectFile = this.handleSelectFile.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.goBack = this.goBack.bind(this);
        this.toggleOnboarding = this.toggleOnboarding.bind(this);
        this.renderIdentityTypes = this.renderIdentityTypes.bind(this);
        this.prefillOrderParams = this.prefillOrderParams.bind(this);
        this.checkInfoToSubmit = this.checkInfoToSubmit.bind(this);
    }

    componentDidMount() {
        this.prefillOrderParams();
    }

    prefillOrderParams() {
        const orderInfo = LocalStorageUtil.loadOrderDetail();
        this.setState({
            selectedIdentityType: orderInfo.identityType ? orderInfo.identityType : this.state.selectedIdentityType,
            documentFront: orderInfo.documentFront ? orderInfo.documentFront : this.state.documentFront,
            documentBack: orderInfo.documentBack ? orderInfo.documentBack : this.state.documentBack,
        });
    }

    renderIdentityTypes(translations) {
        return [
            {
                name: StoreStateConstants.IDENTITY_TYPE_IDENTITY,
                label: translations && translations.choice_IdC,
            },
            {
                name: StoreStateConstants.IDENTITY_TYPE_DRIVING_LICENCE,
                label: translations && translations.choice_DL,
            },
            {
                name: StoreStateConstants.IDENTITY_TYPE_PASSPORT,
                label: translations && translations.choice_psp,
            }
        ];
    }

    toggleOnboarding() {
        this.setState({ showOnboardingGif: !this.state.showOnboardingGif });
    }

    UNSAFE_componentWillUpdate(nextProps) {
        if (nextProps.translations !== this.props.translations) {
            let { translations } = nextProps;
            translations = translations && translations['submission_page'];
            this.setState({
                identity_types: this.renderIdentityTypes(translations)
            })
        }
    }

    goBack() {
        const { history } = this.props;
        history && history.goBack();
    }

    handleSelectIdType(name) {
        this.setState({ selectedIdentityType: name })
    }

    handleSelectFile(e, isDocumentFront) {
        e.preventDefault();
        e && e.stopPropagation();

        const { imgActions } = this.props;
        const _this = this;
        [...e.target.files].forEach((fileInfo, index) => {
            if (index > 0) return;
            let reads = new FileReader();
            reads.readAsDataURL(fileInfo);
            reads.onload = function (e) {
                const imgUrl = this.result;
                _this.toggleLoading();
                imgActions && imgActions.uploadImgToGetUrl(imgUrl)
                    .then(url => {
                        _this.toggleLoading();
                        if (isDocumentFront) {
                            _this.setState({ documentFront: url }, _this.checkInfoToSubmit);
                        } else {
                            _this.setState({ documentBack: url }, _this.checkInfoToSubmit);
                        }
                        e.target.value = '';
                    })
                    .catch((err) => {
                        _this.toggleLoading();
                        e.target.value = '';
                    });
            };
        });
    }

    checkInfoToSubmit() {
        const { orderActions, location } = this.props;
        const orderInfo = LocalStorageUtil.loadOrderDetail();

        if (orderInfo) {
            const { firstName, lastName, email, phone, driveEmail, drivePhone, state, city, country, address, zipCode, vehicleModel, snCode } = orderInfo;
            const { documentFront, documentBack, selectedIdentityType } = this.state;

            const queryParams = queryString.parse(location.search);
            let ad = 'test';
            const localAd = LocalStorageUtil.loadFbAdParams();
            if (localAd && localAd.trim() !== '') {
                ad = localAd;
            }
            if (queryParams.ad && queryParams.ad !== '') {
                ad = queryParams.ad;
            }
            let data = {
                firstName,
                lastName,
                email,
                phone,
                driveEmail,
                drivePhone,
                state,
                city,
                country,
                address,
                zipCode,
                vehicleModel,
                identityType: selectedIdentityType,
                id: '',
                orderNu: '',
                ad
            }
            const orderId = LocalStorageUtil.loadOrderId();
            if (orderId && orderId !== '' && orderId !== 'undefined') {
                data.id = orderId;
            }

            const orderNu = LocalStorageUtil.loadOrderNumber();
            if (orderNu && orderNu !== '' && orderNu !== 'undefined') {
                data.orderNu = orderNu;
            }

            if (country === 'US') {
                data['snCode'] = snCode;
            }
            if (documentFront && documentFront.trim() !== '') {
                data['documentFront'] = documentFront
            }
            if (data.identityType !== StoreStateConstants.IDENTITY_TYPE_PASSPORT && documentBack && documentBack.trim() !== '') {
                data['documentBack'] = documentBack;
            }
            if (queryParams.ad && queryParams.ad !== '') {
                data['ad'] = queryParams.ad;
            }
            if (firstName && firstName.trim() !== '' && lastName && lastName.trim() !== '') {
                let requestData = Object.assign({}, data);
                if (requestData.documentFront && requestData.documentFront.trim() !== '') {
                    requestData.documentFront = requestData.documentFront.replace(BASE_URL, '');
                }
                if (requestData.documentBack && requestData.documentBack.trim() !== '') {
                    requestData.documentBack = requestData.documentBack.replace(BASE_URL, '');
                }
                orderActions && orderActions.updateDriverOrder(requestData);
            }
        }
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    handleSubmitForm() {
        const { selectedIdentityType, documentFront, documentBack } = this.state;

        const { location, orderActions, history } = this.props;

        const queryParams = queryString.parse(location.search);
        let ad = 'test';
        const localAd = LocalStorageUtil.loadFbAdParams();
        if (localAd && localAd.trim() !== '') {
            ad = localAd;
        }
        if (queryParams.ad && queryParams.ad !== '') {
            ad = queryParams.ad;
        }

        let data = {
            identityType: selectedIdentityType,
            documentFront,
            id: '',
            orderNu: '',
            ad
        }

        const orderId = LocalStorageUtil.loadOrderId();
        if (orderId && orderId !== '' && orderId !== 'undefined') {
            data.id = orderId;
        }

        const orderNu = LocalStorageUtil.loadOrderNumber();
        if (orderNu && orderNu !== '' && orderNu !== 'undefined') {
            data.orderNu = orderNu;
        }

        if (data.identityType !== StoreStateConstants.IDENTITY_TYPE_PASSPORT) {
            data['documentBack'] = documentBack;
        }

        let orderData = LocalStorageUtil.loadOrderDetail();
        tracker.trackIdentificationPhoto(`${orderData.firstName} ${orderData.lastName}`);
        sendCustomEvent('AddCredentialsInformation');
        LocalStorageUtil.saveOrderDetail(data);

        this.toggleLoading();
        orderActions && orderActions.updateDriverOrder(data)
            .then(order => {
                this.toggleLoading();

                order.id && order.id !== 0 && LocalStorageUtil.saveOrderId(order.id);
                order.orderNu && order.orderNu !== 0 && LocalStorageUtil.saveOrderNumber(order.orderNu);
                history && history.push({ pathname: PagePathConstants.SELFIE_INFO, search: location.search });
            })
            .catch(() => {
                this.toggleLoading();
            });

    }

    render() {
        const { showError, errorMsg, showLoading, selectedIdentityType, documentFront, documentBack, showOnboardingGif } = this.state;
        const disabledSubmitBtn = (selectedIdentityType.trim() === '' || documentFront.trim() === '' || (selectedIdentityType !== StoreStateConstants.IDENTITY_TYPE_PASSPORT && documentBack.trim() === ''))
        let translations = this.props.translations && this.props.translations['submission_page'];
        return (
            <div className='tw-w-full tw-h-full gotham-font tw-text-[#393c41] '>
                {showLoading && <Loading />}
                <div className='tw-w-full tw-h-full phone:tw-h-full phone:tw-pb-[200px] tw-overflow-scroll'>
                    <div className='tw-z-[100] tw-w-full tw-h-[80px] tw-flex tw-justify-between'>
                        <div className='tw-w-1/3 tw-h-full tw-flex tw-items-center tw-pl-[30px]'><RdsBackIcon color='#171a20' onClick={this.goBack} /></div>
                        <TeslaIcon size={150} color='#171a20' />
                        <div className='tw-w-1/3'></div>
                    </div>

                    {showOnboardingGif ? <div className='tw-w-full tw-text-center tw-flex tw-flex-col tw-items-center'>
                        <div className='tw-text-[32px] phone:tw-text-[26px] tw-font-bold'>認証を受けましょう</div>
                        <div className='tw-text-[20px] phone:tw-text-[16px] tw-mt-[20px]'>以下の簡単な手順に従ってください</div>
                        <div className='tw-mt-[50px] phone:tw-mt-0 tw-w-[660px] phone:tw-w-[calc(100%-40px)] tw-h-[495px] phone:tw-h-[400px]' style={{ backgroundImage: `url(${IDCard})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}></div>
                        <div className='tw-w-full tw-flex tw-justify-center tw-items-center tw-mt-[30px] tw-text-white tw-mt-[10px]'>
                            <RDSButton
                                rectangle
                                className="tw-w-[80%] tw-rounded-[6px]"
                                color="primary"
                                onClick={this.toggleOnboarding}>
                                <span>次のステップ</span>
                            </RDSButton>
                        </div>
                    </div> : <div className='tw-mt-[50px] tw-w-[660px] tw-mx-auto phone:tw-px-[20px] phone:tw-w-full phone:tw-mt-[36px]'>

                        <div className='tw-w-full tw-mt-[50px]' >
                            <div className='tw-text-[#5c5e62] tw-font-[500] tw-text-[28px] phone:tw-text-[22px]'>{translations && translations.title_SelectIT}</div>
                            <span className='tw-text-[14px] tw-font-[500]'>({translations && translations.description_SelectIT})</span>
                            <div className='tw-mt-[10px]'>
                                {this.state.identity_types.map((item) => {
                                    return <div className='tw-mt-[10px] tw-border-border-gray tw-border tw-px-[10px] tw-py-[10px] tw-mb-[10px] tw-rounded-[10px] tw-flex tw-items-center' key={item.name} onClick={() => this.handleSelectIdType(item.name)}>
                                        <RDSRadioButton
                                            name="grade-subject-selector"
                                            checked={item.name === selectedIdentityType}
                                            id="grade-subject-radio"
                                        />
                                        <span className='tw-ml-[15px] tw-items-center tw-text-[14px] tw-font-[500]'>{item.label}</span>
                                    </div>
                                })}
                            </div>

                            <div className='tw-flex phone:tw-flex-wrap tw-justify-between tw-items-start tw-mb-[30px]'>
                                <div className='tw-w-[50%] phone:tw-w-full tw-h-[200px] tw-border tw-relative tw-overflow-hidden'>
                                    <Input className='!tw-border-none tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-[200px] tw-opacity-0 tw-z-[101]' type='file' onChange={(e) => this.handleSelectFile(e, true)} accept="image/*" />
                                    {documentFront !== '' && <div className={`tw-w-full tw-border tw-h-[200px] tw-absolute tw-left-0 tw-top-0`} style={{ backgroundImage: `url(${documentFront})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                                        <WaterMark />
                                    </div>}
                                    {documentFront === '' && <svg style={{ opacity: 0.3, height: '200px' }} className='tw-w-full' viewBox="0 0 375 251" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="64" y="58" width="248" height="137" rx="5" fill="#E3E8EE"></rect>
                                        <rect x="80" y="78" width="216" height="16" rx="1" fill="#C1C9D2"></rect>
                                        <g><path d="M112.85 110H111.151C110.731 110 110.311 110.018 109.893 110.054C101.555 110.764 95.3583 118.251 96.0532 126.777L96.764 135.499C97.4244 143.601 104.049 149.834 112 149.834C119.952 149.834 126.576 143.601 127.237 135.499L127.948 126.777C127.983 126.349 128 125.92 128 125.491C128 116.935 121.217 110 112.85 110Z" fill="#C1C9D2"></path><path d="M111.797 155.558C117.815 155.558 121.187 152.583 127.601 152.583C144 152.583 144 168.952 144 168.952V177.245C144 177.798 143.552 178.245 143 178.245H140H84H81C80.4477 178.245 80 177.798 80 177.245V168.952C80 168.952 80 152.583 96.3994 152.583C104.094 152.583 105.779 155.558 111.797 155.558Z" fill="#C1C9D2"></path></g>
                                        <rect x="161" y="110" width="135" height="8" rx="1" fill="#C1C9D2"></rect>
                                        <rect x="161" y="126" width="100" height="8" rx="1" fill="#C1C9D2"></rect>
                                        <rect x="161" y="142" width="60" height="8" rx="1" fill="#C1C9D2"></rect>
                                    </svg>}
                                </div>
                                {selectedIdentityType !== StoreStateConstants.IDENTITY_TYPE_PASSPORT && <div className='tw-w-[50%] phone:tw-w-full tw-h-[200px] tw-border tw-relative tw-overflow-hidden'>
                                    <Input className='!tw-border-none tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-[200px] tw-opacity-0 tw-z-[101]' type='file' onChange={(e) => this.handleSelectFile(e, false)} accept="image/*" />
                                    {documentBack && <div className='tw-w-full tw-border tw-h-[200px] tw-absolute tw-left-0 tw-top-0 tw-' style={{ backgroundImage: `url(${documentBack})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                                        <WaterMark />
                                    </div>}
                                    {documentBack === '' && <div style={{ backgroundImage: `url(${DocumentBackImg})`, backgroundPosition: 'center', backgroundSize: 'cover' }} className='tw-w-[198px] tw-h-[110px] tw-absolute  tw-top-1/2 tw-left-1/2 tw-ml-[-99px] tw-mt-[-55px] tw-left-0 tw-flex tw-justify-center tw-items-center tw-z-10 tw-opacity-30' />}
                                </div>}
                            </div>
                        </div>

                        {showError && <div className='tw-text-light-red tw-my-[30px] tw-px-[20px]'>{errorMsg}</div>}
                        <div className='tw-w-full tw-flex tw-justify-center tw-items-center tw-mt-[30px] tw-text-white tw-mt-[50px]'>
                            <RDSButton
                                rectangle
                                className="tw-w-[80%] tw-rounded-[6px]"
                                color="primary"
                                onClick={this.handleSubmitForm}
                                disabled={disabledSubmitBtn}>
                                <span>次のステップ</span>
                            </RDSButton>
                        </div>
                    </div>}


                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        translations: TranslationSelectors.selectTranslations(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        imgActions: bindActionCreators(imgActionCreator, dispatch),
        orderActions: bindActionCreators(orderActionCreator, dispatch),
        translationActions: bindActionCreators(translationActionCreator, dispatch),
        globalAlertActions: bindActionCreators(globalAlertActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentInfoPage);
