import ApiEndpointConstants, { BASE_URL } from "../constants/api/ApiEndpointConstants";
import * as PostToAddressAPI from "../apis/PostToAddressAPI";
import { showErrorGlobalAlert } from './globalAlertActionCreator';

export const getFullAddressByZipcode = (zipcode) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      PostToAddressAPI.getFullAddressByZipcode(zipcode)
        .then((res) => {
          if (res.status === 200 && res.data.code === 1) {
            return resolve(res.data.data);
          }
          return reject('err');
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            dispatch(showErrorGlobalAlert(err.response.data.msg));
          }
          return reject(err);
        });
    });

  };
};

export const getStateByZipcode = (zipcode) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      PostToAddressAPI.getStateByZipcode(zipcode)
        .then((res) => {
          if (res.status === 200 && res.data.code === 1) {
            return resolve(res.data.data);
          }
          return reject('err');
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            dispatch(showErrorGlobalAlert(err.response.data.msg));
          }
          return reject(err);
        });
    });

  };
};