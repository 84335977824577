import { Map } from 'immutable';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ActionConstants from '../constants/actions/ActionConstants';


const initialState = Map({
});

export const OrderReducer = (state = initialState, action) => {
  switch (action.type) {
    
    default:
      return state;
  }
};
