import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "./index.scss";
import CheckoutForm from "./CheckoutForm";

export default function StripeCustomPayment(props) {
    const { clientSecret, stripePkCode } = props;
    const options = {
        clientSecret,
        appearance: {
            theme: 'stripe',
        },
        locale: 'ja',
    };
    const stripePromise = loadStripe(stripePkCode);

    return (
        <React.Fragment>
            {clientSecret && clientSecret !== '' && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm {...props} />
                </Elements>
            )}
        </React.Fragment>
    );
}