import ApiEndpointConstants from '../constants/api/ApiEndpointConstants';
import api from './API';

export const submitDriveOrder = (data) => {
    const tgid = localStorage.getItem('tgid');
    const amount = localStorage.getItem('payment_amount');
    const amount_fast = localStorage.getItem('payment_amount_fast');
    const newData = Object.assign({}, data, {tgid, amount, amount_fast});
    return api.post(
        `${ApiEndpointConstants.ORDER}`,
        { data: JSON.stringify(newData) },
    );
}

export const updateDriveOrder = (data) => {
    const tgid = localStorage.getItem('tgid');
    const newData = Object.assign({}, data, {tgid});
    return api.post(
        `${ApiEndpointConstants.ORDER_UPDATE}`,
        { data: JSON.stringify(newData) },
    );
}

export const updateOrderStatus = (params) => {
    return api.post(
        `${ApiEndpointConstants.ORDER_STATUS_UPDATE}`,
        params,
    );
}