import { Map } from 'immutable';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ActionConstants from '../constants/actions/ActionConstants';

const initialState = Map({
  [StoreStateConstants.TRANSLATION_RESOURCE]: Map(),
});

export const TranslationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionConstants.UPDATE_TRANSLATIONS_RESOURCE: {
      const {page, resource} = action.payload;
      return state.setIn([StoreStateConstants.TRANSLATION_RESOURCE, page], Map(resource));
    }
    default:
      return state;
  }
};
