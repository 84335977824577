import { combineReducers } from 'redux-immutable';
import { routerReducer } from 'react-router-redux';
import { GlobalAlertReducer } from './GlobalAlertReducer';
import { TranslationReducer } from './TranslationsReducer';
import { CountdownReducer } from './CountdownReducer';
import { OrderReducer } from './OrderReducer';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const appReducer = combineReducers({
  [ReducerNameConstants.GLOBAL_ALERT]: GlobalAlertReducer,
  [ReducerNameConstants.TRANSLATION]: TranslationReducer,
  [ReducerNameConstants.ORDER]: OrderReducer,
  [ReducerNameConstants.COUNTDOWN]: CountdownReducer,
  router: routerReducer,
});

const rootReducer = (state, action) => {
  let newState = state;
  return appReducer(newState, action);
};

export default rootReducer;
